    .sample h2 {
        text-align: center;
        font-size: 17px;
        color: #EDA90F;
    }
    
    .homePage {
        /* background: url("images/employee.jpg"); */
        background: linear-gradient(rgba(11, 11, 11, 0.5), rgba(10, 10, 10, 0.5));
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100% 120%;
    }
    
    .homeImage1 {
        width: 100%;
    }
    
    .homeImage1 h2 {
        transform: translate(-50);
        top: 30%;
        left: 30%;
        position: absolute;
        color: #fff;
    }
    
    .homeImage1 img {
        width: 100%;
        height: 20rem;
    }
    
    .container img {
        width: 100%;
        height: 500px;
    }
    
    .container {
        margin: 0px;
        width: 100%;
    }
    /* about css start*/
    
    .about-page {
        width: 100%;
        /* background-color: #0B598F; */
        /* For browsers that do not support gradients */
        /* background-image: linear-gradient(to bottom right, #0B598F, #765232); */
    }
    
    .about-page h2 {
        font-size: 40px;
        /* margin: 0;
        position: absolute;
        top: 30%;
        left: 50%;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%); */
        padding: 40px;
        text-align: center;
        color: #111;
    }
    
    .about-section {
        margin-left: 50px;
        margin-right: 50px;
    }
    
    .about {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(21rem, 1fr));
        gap: 1.5rem;
        margin-top: 2rem;
    }
    
    .about_box1 {
        background: hsl(205, 27%, 88%);
        padding: 20px;
        font-size: 20px;
        color: hsl(0, 43%, 1%);
        /* border-right: 4px solid #111; */
        border-radius: 10px;
    }
    
    .about_box2 {
        padding: 20px;
        border-radius: 10px;
        background: #0B598F;
        /* background: #0B598F; */
    }
    
    .about_box2 h2 {
        margin-left: 40px;
        color: #EDA90F;
        /* background: #0B598F; */
    }
    
    .about_box2 p {
        font-size: 20px;
        color: #EDA90F;
        border-left: 2px solid #EDA90F;
    }
    
    .about_box2 span {
        font-size: 16px;
        padding: 5px;
        color: #fff;
    }
    
    .readmore {
        margin: 30px;
    }
    /* about css end*/
    /* contact css start*/
    
    .contact {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(21rem, 1fr));
        gap: 1.5rem;
        margin-top: 2rem;
        padding: 100px;
    }
    
    .contact-box span {
        padding-left: 15px;
    }
    /* contact css end*/
    /* service css start*/
    
    .service {
        margin-left: 50px;
        margin-right: 50px;
    }
    
    .service_list {
        display: block;
        justify-content: space-between;
        gap: 20px;
    }
    
    .service_list p {
        text-align: center;
        font-size: 17px;
        color: #EDA90F;
    }
    
    .service1 {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(18rem, 1fr));
        gap: 1.5rem;
        margin-top: 2rem;
    }
    
    .service2 {
        display: flex;
        /* background: #343265;
    padding: 30px; */
        justify-content: center;
    }
    
    .service3 {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(18rem, 1fr));
        gap: 1.5rem;
        margin-top: 2rem;
    }
    
    .box {
        padding: 15px;
        justify-content: center;
    }
    
    .box img {
        display: flex;
        height: 120px;
        text-align: center;
    }
    
    .odd_box {
        background-color: #a5a3dc;
        width: 70%;
        border-radius: 10px;
        text-align: center;
        font-size: 20px;
        padding: 20px;
    }
    
    .odd_box p {
        font-size: 20px;
        color: black;
    }
    
    .box h2 {
        font-size: 20px;
    }
    
    .box p {
        font-size: 15px;
        text-align: justify;
        color: black;
    }
    /* service css end*/
    /* sample software start */
    
    .sample {
        margin-left: 50px;
        margin-right: 50px;
    }
    
    .sample_software {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(21rem, 1fr));
        gap: 2rem;
        margin-top: 2rem;
    }
    
    .sample1 {
        display: flex;
        justify-content: center;
        gap: 4px;
        /* border-radius: 20px;
        border-right: 5px solid #a5a3dc;
        border-left: 5px solid #a5a3dc; */
    }
    
    .sample-box {
        margin: 20px;
        background: #2c3e50;
    }
    /*     
    .sample-box button {
        margin-bottom: 15px;
    } */
    
    .sample-box h2 {
        font-size: 20px;
        text-align: left;
        color: #EDA90F;
        /* padding-top: 20px;
        padding-left: 20px;
        padding-right: 20px; */
        padding: 10px;
    }
    
    .sample-box p {
        font-size: 18px;
        padding: 30px;
        text-align: left;
        color: aliceblue;
        margin-top: -20px;
    }
    
    a {
        text-decoration: none;
        text-align: center;
        color: #fff;
        font-size: 20px;
    }
    
    .sample2 {
        display: flex;
        justify-content: center;
        border-radius: 20px;
    }
    /* sample css end */
    /* team member css */
    
    .team_member {
        margin-left: 50px;
        margin-right: 50px;
    }
    
    .team_member h2 {
        text-align: center;
        font-size: 17px;
        color: #EDA90F;
        ;
    }
    
    .member {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(18rem, 1fr));
        gap: 1.5rem;
        margin-top: 2rem;
        justify-content: center;
    }
    
    .member_list {
        padding: 20px;
        border-radius: 10px;
        /* border: 1px solid #111; */
        background-color: #f8f9fc;
    }
    
    .member_list p {
        text-align: center;
    }
    
    .member_list span {
        color: #EDA90F;
    }
    
    .member_list img {
        height: 200px;
        /* border-radius: 50%; */
        align-items: center;
        justify-content: center;
    }
    /* team member css start */
    /* footer css start */
    
    .footer {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(18rem, 1fr));
        gap: 1.5rem;
        margin-top: 2rem;
        background: #2c3e50;
        padding: 50px;
    }
    
    .footer-box {
        margin-left: 30px;
        margin-right: 30px;
    }
    
    .footer-box h2 {
        font-size: 25px;
        color: #EDA90F;
    }
    
    .footer-box p {
        font-size: 15px;
        color: #fff;
    }
    
    .footer-box span {
        font-size: 15px;
        padding-left: 5px;
        color: #fff;
    }
    
    .footer-box .media {
        display: flex;
    }
    
    .footer-box h3 {
        padding-left: 10px;
    }
    
    .foot {
        border-top: 1px solid #fff;
        display: flex;
        justify-content: center;
        background: #2c3e50;
        color: #fff;
        padding-top: 4px;
    }
    /* footer css  end */
    /* review css start */
    
    .reviews {
        margin-left: 50px;
        margin-right: 50px;
    }
    
    .heading {
        text-align: center;
    }
    
    .heading h3 {
        font-size: 25px;
    }
    
    .heading span {
        font-size: 20px;
        color: #EDA90F;
    }
    
    .reviews .clients-reviews {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(18rem, 1fr));
        gap: 1.5rem;
        margin-top: 2rem;
    }
    
    .reviews .clients-reviews .review {
        padding: 1.5rem;
        background: #fff;
        border: 1px solid #aaa;
    }
    
    .review .info {
        display: flex;
        align-items: center;
        gap: 1rem;
        margin-bottom: .5rem;
    }
    
    .review .info img {
        height: 3rem;
        width: 3rem;
        border-radius: 50%;
    }
    
    .review .info .row h1 {
        font-size: 1.3rem;
        font-weight: 500;
    }
    
    .review .info .row span {
        font-size: 12px;
        color: #ec9f6b;
    }
    
    .review p {
        font-size: 18px;
    }
    /* review css end */
    /* about css start  */
    
    .mission {
        margin-right: 50px;
        margin-left: 50px;
    }
    
    .mission h2 {
        font-size: 20px;
        color: #EDA90F;
        text-align: center;
    }
    
    .mission_vision {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(18rem, 1fr));
        gap: 1.5rem;
        margin-top: 2rem;
    }
    
    .mision {
        border: 1px solid #111;
    }
    
    .mision p {
        padding-left: 40px;
        padding-right: 40px;
        text-align: justify;
    }
    
    .vision p {
        padding-left: 40px;
        padding-right: 40px;
        text-align: justify;
    }
    
    .mision img {
        width: 50px;
    }
    
    .vision img {
        width: 50px;
    }
    
    .vision {
        border: 1px solid #111;
    }
    /* addmin side bar css start */
    
    .sidebar {
        background: #112232;
        display: block;
        width: 200px;
    }
    
    .sidebar_box {
        margin: 20px;
        width: 160px;
    }
    
    .active {
        background-color: #1d4ed8;
    }
    
    nav ul li a:not(.active):hover {
        background-color: #172554;
    }
    /* admin sidebar css end */
    /* admin hedear css start */
    
    .admin-header {
        display: flex;
        justify-content: space-between;
        background: #112232;
        padding: 20px;
    }
    
    .admin-header h2 {
        color: #fff;
    }
    /* admin hedear css end */
    /* new css start */
    
    .add-news {
        margin-left: 50px;
        margin-right: 50px;
    }
    
    .news {
        display: flex;
        justify-content: center;
        gap: 40px;
    }
    
    .news-content {
        border: 1px solid #111;
    }
    
    .news-content p {
        background: #2c3e50;
        font-size: 20px;
        color: #fff;
        padding: 2px;
        text-align: center;
    }
    
    .new {
        padding: 10px;
    }
    
    .announcement {
        border: 1px solid #111;
    }
    
    .announcement p {
        background: #2c3e50;
        font-size: 20px;
        color: #fff;
        padding: 2px;
        text-align: center;
    }
    /* ScrollToTopButton.css */
    
    .scroll-to-top-button {
        position: fixed;
        bottom: 20px;
        right: 20px;
        background-color: #007bff;
        color: #fff;
        border: none;
        border-radius: 50%;
        padding: 10px;
        cursor: pointer;
        /* z-index: 1000; */
    }
    
    .scroll-to-top-button:hover {
        background-color: #0056b3;
    }
    /* home site css start */
    
    .homesite-container {
        box-shadow: 20px 20px 50px 15px grey;
        padding-top: 30px;
    }
    
    .homesite-container h2 {
        /* text-align: center; */
        font-size: 30px;
        color: #ec9f6b;
        padding-left: 40px;
    }
    
    .homesite {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(18rem, 1fr));
        gap: 1.5rem;
        /* box-shadow: 20px 20px 50px 15px grey; */
        padding-bottom: 20px;
        justify-content: center;
    }
    
    .content ul {}
    
    .content li {
        list-style: none;
        padding-left: 30px;
        font-size: 25px;
    }
    
    .content ul li span {
        padding-left: 10px;
    }
    
    .content ul h2 {
        font-size: 25px;
        color: #1d4ed8;
        text-align: left;
        padding-left: 10px;
    }
    
    .home-image img {
        height: 300px;
        width: 100%;
    }
    
    .marquee {
        width: 50%;
        /* border: 1px solid red; */
        white-space: nowrap;
        overflow: hidden;
        /* position: relative; */
    }
    
    .marquee span {
        display: inline-block;
        animation: marquee 10s linear infinite;
    }
    
    @keyframes marquee {
        0% {
            transform: translateX(50vw);
        }
        100% {
            transform: translateX(-50%);
        }
    }
    /* Admin dashboard css start */
    
    .sidebar ul li:hover {
        background-color: rgb(117, 117, 117);
        border-radius: 5px;
        cursor: pointer;
    }
    
    .sidebar ul li:hover a {
        color: white;
    }
    
    .active {
        background-color: rgb(117, 117, 117);
        border-radius: 5px;
        color: white;
    }
    
    .slick-slide>div {
        margin: 0 10px;
        position: relative;
    }
    /* Admin dashboard css end */
    /* admin css  start*/
    
    .admin-header {
        background: #0f172a;
        width: 100;
        display: flex;
        justify-content: space-between;
        padding: 10px;
        color: white;
        list-style: none;
    }
    
    .admin-content {
        display: flex;
    }
    
    .admin-sideBar1 {
        background: #0f172a;
    }
    
    .admin-sideBar1 ul {}
    
    .admin-sideBar1 li {
        list-style: none;
        text-decoration: none;
        padding: 20px;
    }
    
    .admin-sideBar1 li a {
        text-decoration: none;
        line-height: 20px;
        font-size: 15px;
        color: white;
    }
    
    .admin-sideBar2-header {
        text-align: center;
    }
    /*  css end for admin */
    /*login css start*/
    
    .login-form {
        background-color: hsl(345, 4%, 81%);
    }
    
    .login-box {
        background-color: hsl(340, 69%, 76%);
    }
    
    .login-box h2 {
        text-align: center;
    }
    /* src/App.css or src/components/PrivacyPolicy.css */
    
    .privacy-policy {
        max-width: 800px;
        margin: 0 auto;
        padding: 20px;
        line-height: 1.6;
    }
    
    .privacy-policy h1 {
        margin-bottom: 20px;
    }
    
    .privacy-policy p {
        margin-bottom: 15px;
    }
    /*login css start*/
    /* ipad design */
    
    @media (max-width:820px) {
        .service {
            margin-left: 10px;
            margin-right: 10px;
        }
        .odd_box {
            background-color: #a5a3dc;
            width: 980%;
            border-radius: 10px;
            text-align: center;
            font-size: 20px;
            padding: 10px;
        }
        .sample {
            margin-left: 10px;
            margin-right: 10px;
        }
        .team_member {
            margin-left: 10px;
            margin-right: 10px;
        }
        .about-section {
            margin-left: 10px;
            margin-right: 10px;
        }
        .about_box1 {
            background: hsl(205, 27%, 88%);
            padding: 20px;
            font-size: 18px;
            color: hsl(0, 43%, 1%);
            /* border-right: 4px solid #111; */
        }
        .about_box2 {
            margin: 0px;
            border-radius: 10px;
            /* border-left: 3px solid #111; */
            background: #0B598F;
            /* background: #0B598F; */
        }
        .about_box2 h2 {
            margin-left: 40px;
            color: #EDA90F;
            /* background: #0B598F; */
        }
        .about_box2 p {
            font-size: 15px;
            color: #EDA90F;
        }
        .about_box2 span {
            font-size: 16px;
            color: #fff;
        }
        /* home site css start */
        .homesite {
            box-shadow: 20px 20px 50px 15px grey;
            padding: 20px;
        }
        .content ul {}
        .content li {
            list-style: none;
            padding-left: 30px;
            font-size: 15px;
        }
        .content ul h2 {
            font-size: 20px;
            color: #1d4ed8;
        }
        .home-image img {
            height: 150px;
        }
        /* ScrollToTopButton.css */
        .scroll-to-top-button {
            position: fixed;
            bottom: 20px;
            right: 20px;
            background-color: #007bff;
            color: #fff;
            border: none;
            border-radius: 50%;
            padding: 10px;
            cursor: pointer;
            /* z-index: 1000; */
        }
        .scroll-to-top-button:hover {
            background-color: #0056b3;
        }
        .hero {
            margin: 0px 120px;
            /* padding-top: -1000px; */
            /* position: absolute; */
        }
        .hero-text {
            color: #fff;
            font-size: 30px;
            font-weight: 500;
            line-height: 30px;
            text-align: center;
        }
        .hero-explore {
            display: flex;
            align-items: center;
            gap: 50px;
            width: fit-content;
            margin-top: 120px;
            padding: 5px 8px;
            padding-left: 30px;
            border-radius: 60px;
            background-color: #fff;
            cursor: pointer;
        }
        .hero-explore p {
            color: #0056b3;
            font-size: 10px;
        }
        .hero-dot-play {
            margin-top: 100px;
            display: flex;
            justify-content: space-between;
        }
        .hero-dots {
            display: flex;
            align-items: center;
            list-style: none;
        }
        .hero-dot {
            width: 15px;
            height: 15px;
            background: #7a3131;
            border-radius: 7.5px;
            cursor: pointer;
            text-align: center;
        }
        .hero-dot.orange {
            background: orangered;
        }
        .hero-play {
            /* margin-left: 400px; */
            border-radius: 10px;
            background: #fff;
            display: flex;
            margin-top: -100px;
            align-items: center;
            gap: 30px;
            height: 50px;
        }
        .hero-play p {
            color: #0056b3;
            font-size: 10px;
            padding-right: 10px;
        }
    }